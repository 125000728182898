import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { StyledMenuIcon, StyledSideBar, StyledInputSearch, StyledSpace } from './styles'
import { Avatar, Badge, Dropdown, Layout, Menu, Space, Button } from 'antd'
import { useRouter } from 'next/router'
import { useAdminAuth } from '@/contexts/adminAuth'
import {
  BellFilled,
  DashboardFilled,
  GiftFilled,
  ShopFilled,
  ShoppingFilled,
  TagFilled,
  UserOutlined,
  CloseOutlined,
  StockOutlined,
} from '@ant-design/icons'
import '@/styles/antd.less'
import { Content } from 'antd/lib/layout/layout'
import {
  StyledHeaderWrapper,
  StyledMenuBtn,
  StyledNotificationsItem,
  StyledNotificationsWrapper,
  StyledOptionsText,
  StyledUserText,
} from '../RetailerLayout/styles'
import adminApi from '@/services/adminApi'
import { NotificationProps } from '@/Props/NotificationProps'
import { formatDistance } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { useFetch } from '@/utils/useFetch'
import { FaUser } from 'react-icons/fa'
import { AiFillSetting } from 'react-icons/ai'
import AdminGlobalStyle from '@/styles/AdminGlobalStyle'
const { SubMenu } = Menu
const { Header, Sider } = Layout
const notifyMe = (notificationId: number, notificationMessage: string) => {
  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
  }
  // Let's check whether notification permissions have alredy been granted
  else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    const audio = new Audio('/notification.mp3')
    audio.volume = 0.5
    audio.muted = false
    audio.play()
    new Notification(notificationMessage, { tag: notificationId.toString() })
  }
  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const audio = new Audio('/notification.mp3')
        audio.volume = 0.5
        audio.muted = false
        audio.play()
        new Notification(notificationMessage, { tag: notificationId.toString() })
      }
    })
  }
  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
}
const AdminLayout: React.FC = ({ children }) => {
  const router = useRouter()
  const { isAuthenticated, user, logout } = useAdminAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [openKeys, setOpenKeys] = useState([])
  const [openMenu, setOpenMenu] = useState(false)
  const { data: notifications } = useFetch<NotificationProps[]>(`notifications`)
  useEffect(() => {
    if (!isAuthenticated || !user) {
      logout()
      return
    }
    if (!user?.is_admin) {
      logout()
      //router.replace('/')
    }
    if (router.pathname && (router.pathname == '/products' || router.pathname == '/categories')) {
      setOpenKeys(['products'])
    }
    setIsLoading(false)
  }, [isAuthenticated, router, user, logout])
  const handleShowNotifications = async (isVisible: boolean) => {
    if (isVisible) {
      await Promise.all(
        unreadedNotifications.map(async (notification) => {
          await adminApi.put(`notifications/${notification.id}`, {
            is_admin_read: true,
          })
        })
      )
    }
  }
  const OpenMenuMobile = () => {
    setOpenMenu(!openMenu)
  }
  const optionsMenu = (
    <Menu>
      <Menu.Item>
        <Link href={`/settings/users/edit/${user?.id}`}>
          <a>
            <StyledOptionsText>Ver perfil</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/settings/users">
          <a>
            <StyledOptionsText>Utilizadores</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/settings/notifications">
          <a>
            <StyledOptionsText>Notificações</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <StyledMenuBtn type="link" onClick={() => logout()} danger>
          Terminar sessão
        </StyledMenuBtn>
      </Menu.Item>
    </Menu>
  )
  const notificationsMenu = (
    <StyledNotificationsWrapper direction="vertical" size={0}>
      {notifications?.slice(0, 5).map((notification, key) => {
        let url = ''
        if (notification.message.includes('Exportação')) {
          const splited = notification.message.split(' ')
          const findIndex = splited.findIndex((word) => word.includes('https'))
          if (findIndex) {
            url = splited[findIndex]
            notification.message = splited.slice(0, findIndex).join(' ')
          }
        }
        return (
          <a
            href={
              notification.order_id
                ? `/orders/${notification.order_id}`
                : notification.message && notification.message.includes('Exportação') && url != ''
                ? url
                : '#'
            }
            key={key}
            style={{ textDecoration: 'none' }}
          >
            <StyledNotificationsItem className="notification-item" direction="vertical" size={0}>
              <p>{notification.message}</p>
              <span>
                {formatDistance(new Date(notification.created_at), new Date(), {
                  includeSeconds: true,
                  locale: pt,
                }).replace('aproximadamente', '')}
              </span>
            </StyledNotificationsItem>
          </a>
        )
      })}
      <Link href="/settings/notifications">
        <a> Ver todas </a>
      </Link>
    </StyledNotificationsWrapper>
  )
  if (isLoading || !notifications) {
    return <></>
  }
  if (notifications.length > 0) {
    let haveNotifications = false
    notifications.map((notification) => {
      if (!notification.is_admin_notified) {
        haveNotifications = true
        adminApi.put(`notifications/${notification.id}`, {
          is_admin_notified: true,
        })
      }
    })
    if (haveNotifications) {
      notifyMe(
        notifications[notifications.length - 1].id,
        notifications[notifications.length - 1].message
      )
    }
  }
  const unreadedNotifications = notifications.filter(
    (notification) => notification.is_admin_read == false
  )
  return (
    <>
      <AdminGlobalStyle />
      <Layout style={{ minHeight: '100%' }}>
        <Header
          style={{
            boxShadow: '0px 3px 5px -1px rgba(67, 4, 59, 0.06)',
            zIndex: 9,
            position: 'fixed',
            width: '100%',
          }}
        >
          <StyledHeaderWrapper>
            <Link href="/">
              <a className="principal-logo">
                <img
                  src="/assets/images/swig-logo-purple.svg"
                  alt="SWIG"
                  width="94"
                  height="32"
                  className="logo-admin-page"
                />
              </a>
            </Link>
            <Button type="link" onClick={() => OpenMenuMobile()} className="open-button-admin">
              <StyledMenuIcon />
            </Button>
            <Space direction="horizontal" size={28} className="search-input">
              {/*
              <StyledInputSearch
                placeholder="Pesquisar"
                size="middle"
                bordered={false}
                allowClear
              />
              */}
              {/*<StyledLanguageText>
              PT
              <CaretDownOutlined />
            </StyledLanguageText>*/}
              <Dropdown overlay={optionsMenu}>
                <a className="ant-dropdown-link">
                  <Avatar
                    style={{ backgroundColor: '#F8F5F8', color: '#7B2E6F' }}
                    icon={<UserOutlined />}
                  ></Avatar>
                  <StyledUserText>{user?.name}</StyledUserText>
                </a>
              </Dropdown>
              <Dropdown
                overlay={notificationsMenu}
                onVisibleChange={(visible) => handleShowNotifications(visible)}
              >
                <Badge
                  count={unreadedNotifications.length}
                  style={{ backgroundColor: '#F8F5F8', color: '#7B2E6F' }}
                >
                  <BellFilled size={20} style={{ fontSize: '20px', color: '#000' }} />
                </Badge>
              </Dropdown>
            </Space>
          </StyledHeaderWrapper>
        </Header>

        <Layout>
          <Sider className={openMenu ? 'sidebar-admin active' : 'sidebar-admin '}>
            <StyledSideBar>
              <StyledSpace>
                <Space
                  direction="horizontal"
                  size={0}
                  className="sidebar-header"
                  style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                >
                  <a>
                    {' '}
                    <img
                      src="/assets/images/swig-logo-purple.svg"
                      alt="SWIG"
                      className="logo-admin-page-sidebar"
                    />{' '}
                  </a>
                  <Button type="link" onClick={() => OpenMenuMobile()} className="close-button">
                    <CloseOutlined />
                  </Button>
                </Space>
                <Space className="search-space-mobile">
                  <StyledInputSearch
                    placeholder="Pesquisar"
                    size="middle"
                    bordered={false}
                    allowClear
                  />
                </Space>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[router.pathname]}
                  defaultOpenKeys={openKeys}
                  className="menu-sidebar"
                >
                  <Menu.Item key="/" icon={<DashboardFilled />}>
                    <Link href="/">
                      <a> Início </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/orders" icon={<ShoppingFilled />} style={{ fontSize: '16' }}>
                    <Link href="/orders">
                      <a> Encomendas </a>
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="products"
                    icon={<TagFilled />}
                    title="Produtos"
                    style={{ fontSize: '16' }}
                  >
                    <Menu.Item key="/products">
                      <Link href="/products">
                        <a style={{ paddingLeft: 25 }}> Produtos </a>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/categories">
                      <Link href="/categories">
                        <a style={{ paddingLeft: 25 }}> Categorias </a>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/attributes">
                      <Link href="/attributes">
                        <a style={{ paddingLeft: 25 }}> Atributos </a>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/attributes-groups">
                      <Link href="/attributes-groups">
                        <a style={{ paddingLeft: 25 }}> Grupo de atributos </a>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="/promo-codes" icon={<GiftFilled />} style={{ fontSize: '16' }}>
                    <Link href="/promo-codes">
                      <a> Códigos de desconto </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/sponsored-products"
                    icon={<StockOutlined />}
                    style={{ fontSize: '16' }}
                  >
                    <Link href="/sponsored-products">
                      <a> Produtos patrocinados </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/retailers" icon={<ShopFilled />} style={{ fontSize: '16' }}>
                    <Link href="/retailers">
                      <a> Retalhistas </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/customers" icon={<FaUser />} style={{ fontSize: '16' }}>
                    <Link href="/customers">
                      <a> Clientes </a>
                    </Link>
                  </Menu.Item>
                </Menu>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[router.pathname]}
                  defaultOpenKeys={openKeys}
                  className="menu-sidebar-bottom"
                >
                  <Menu.Item
                    key="/settings"
                    icon={<AiFillSetting />}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Link href="/settings">
                      <a> Definições </a>
                    </Link>
                  </Menu.Item>
                </Menu>
              </StyledSpace>
            </StyledSideBar>
          </Sider>
          <Content style={{ marginTop: 64 }}>{children}</Content>
        </Layout>
      </Layout>
    </>
  )
}
export default AdminLayout
