import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { Form, Input, Space } from 'antd'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  background-color: #f0f0f0;
  min-height: 100vh;
`

export const Navbar = styled.div`
  grid-column: 1 / span 2;
  padding: 20px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Aside = styled.div`
  grid-column: 1;
  background-color: #f16749a3;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  margin: 16px;
  padding: 30px;
  background: #fff;
  flex: 1;
  position: relative;

  @media (max-width: 767px) {
    margin: 24px;
    padding: 16px;
  }
`
export const StyledForm = styled(Form)`
  & .ant-select-selection-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
  }

  & .ant-input-lg {
    padding: 13.5px 11px;
  }

  & .ant-input-group-addon {
    font-family: Lato;
    color: #6b6b6b;
  }

  & .seo,
  & .edit-seo-btn {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  & .seo.title {
    font-size: 18px;
    line-height: 24px;
    color: #1a0dab;
    text-align: left;
  }

  & .seo.link {
    font-size: 14px;
    line-height: 24px;
    color: #006621;
    text-align: left;
  }

  & .seo.description {
    font-size: 14px;
    line-height: 24px;
    color: #545454;
    text-align: left;
  }

  & .edit-seo {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  & .ant-divider-horizontal {
    margin: 0;
  }

  & .ant-card-body .ant-row.ant-form-item:last-child {
    margin-bottom: 0;
  }

  & .ant-checkbox + span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b6b6b;
  }

  & .ant-row .ant-col:nth-child(3),
  & .ant-row .ant-col:nth-child(4) {
    margin-top: 24px;
  }

  & .label-bold .ant-form-item-label label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #393939;
  }
`
export const HoraryAllWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 25px;

  & > .ant-row {
    margin: 0;
    width: 100%;
  }

  & > .ant-row .ant-picker {
    width: 100%;
  }

  & > .ant-row .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-weight: normal;
    font-style: normal;
  }

  & > .ant-row .ant-form-item-control-input-content > .ant-row {
    margin: 0;
    width: calc(50% - 15px);
  }

  & > input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  @media screen and (max-width: 576px) {
    padding-left: 0;

    & > .ant-row .ant-form-item-control-input-content {
      flex-direction: column;
      gap: 12px;
    }

    & > .ant-row .ant-form-item-control-input-content > .ant-row {
      width: 100%;
    }
  }
`
export const HoraryEachWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-left: 25px;

  & .ant-checkbox-wrapper {
    flex: 0 25%;
  }

  & > .ant-row {
    flex: 0 75%;
  }

  & > .ant-row .ant-form-item-control-input-content {
    justify-content: space-between;
    display: flex;
    font-family: Lato;
    font-weight: normal;
    font-style: normal;
  }

  & > .ant-row .ant-form-item-control-input-content > .ant-row {
    width: calc(50% - 15px);
    margin: 0;
  }

  & > .ant-row .ant-form-item-control-input-content > .ant-row .ant-picker {
    width: 100%;
  }

  & > input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 16px;
    padding-left: 0;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    & > .ant-row .ant-form-item-control-input-content > .ant-row {
      width: 100%;
      gap: 8px;
    }
  }
`
export const StyledMenuIcon = styled(MenuOutlined)``
export const StyledSpace = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
`

export const StyledSideBar = styled.div`
  height: 100%;

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    color: #6b6b6b;
    min-width: 16px;
    margin-right: 12px;
    font-size: 20px;
    margin-bottom: 1px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  & .ant-menu-item-selected .anticon {
    color: #7b2e6f;
  }
  & .ant-menu-item-selected .ant-menu-item-icon {
    color: #7b2e6f;
  }

  & .ant-menu-submenu-open .ant-menu-submenu-title .anticon-tag {
    color: #7b2e6f;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
    padding-left: 16px !important;
    display: flex;
    align-items: center;
  }
  .close-button {
    color: black;
    margin-left: 200px;
    display: none;
  }
  @media (max-width: 800px) {
    .close-button {
      display: inline;
    }
  }
`
export const StyledInputSearch = styled(Input.Search)`
  background: #f4f4f4;
  width: 447px;
  display: flex;
  font-size: 16px;

  @media (max-width: 726px) {
    display: none;
  }

  & button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: unset;
    background: #f4f4f4;
    color: #393939;
  }

  & .ant-input {
    @media (min-width: 801px) {
      line-height: 20px;
    }
  }
`
export const StyledSpaceProducts = styled(Space)`
  display: flex;
  .autocomplete-products-page {
    width: 100% !important;
    margin-right: 10px;
  }
  .select-category-products-page {
    width: 100% !important;
    margin-right: 10px;
  }
  .select-price-products-page {
    width: 100% !important;
    margin-right: 10px;
  }
  .select-select-wrapper {
    display: flex;
    flex-direction: row;
  }
  .selects-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  li.ant-pagination-options {
    display: none !important;
  }
  & .ant-input-affix-wrapper {
    padding: 8px 12px;
  }
  .autocomplete {
    width: 50% !important;
    margin-right: 15px;
  }
  .select-category {
    width: 25% !important;
    margin-right: 15px;
  }
  .select-price {
    width: 25% !important;
  }

  @media (min-width: 1250px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 150px;
    }
  }
  @media (max-width: 1249px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 125px;
    }
  }
  @media (max-width: 991px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 100px;
    }
  }
  @media (max-width: 900px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: unset;
    }
  }
  @media (max-width: 900px) {
    .selects-wrapper {
      flex-direction: column;
    }
    .autocomplete-products-page {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 15px;
    }
    .select-category-products-page {
      width: 100% !important;
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .select-price-products-page {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 15px;
    }
    body.table-page
      main.ant-layout-content
      &
      .ant-space-item
      .selects-wrapper
      div:nth-child(n + 2) {
      width: 100% !important;
    }
    .select-select-wrapper {
      display: flex;
      flex-direction: row;
    }
    .selects-wrapper div:nth-child(3) div:nth-child(2) span.ant-select-arrow {
      top: 40%;
    }
  }

  & > .ant-space-item:nth-child(1) .ant-select-show-search {
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }

    & .ant-input-group {
      padding: 0;
    }
  }
`
export const StyledSearch = styled(Input.Search)`
  & .ant-input-affix-wrapper {
    padding: 10.5px 12px;
    border-right: unset;
  }

  & .ant-input-affix-wrapper:hover + .ant-input-group-addon .ant-btn {
    border-color: #87487c;
  }

  & .ant-input-search-button {
    height: 32px;
    border: 1px solid #e2e2e2;
  }
`
