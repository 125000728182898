import styled from 'styled-components'
import { Input, Button, Space, Menu } from 'antd'
import Text from 'antd/lib/typography/Text'
import { MenuOutlined } from '@ant-design/icons'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  background-color: #f0f0f0;
  min-height: 100vh;
`

export const Navbar = styled.div`
  grid-column: 1 / span 2;
  padding: 20px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Aside = styled.div`
  grid-column: 1;
  background-color: #f16749a3;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  margin: 30px;
  padding: 30px;
  background: #fff;
  flex: 1;
`
export const StyledInputSearch = styled(Input.Search)`
  background: #f4f4f4;
  width: 447px;
  display: flex;
  font-size: 16px;
  height: 40px;
  .ant-input::-webkit-input-placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }

  .ant-input:-moz-placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }

  .ant-input::-moz-placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }

  .ant-input:-ms-input-placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }

  .ant-input::input-placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }

  .ant-input::placeholder {
    font-family: Lato;
    color: #9e9e9e;
  }
  @media (max-width: 850px) {
    width: 400px;
  }

  @media (max-width: 726px) {
    display: none;
  }

  & button.ant-btn.ant-btn-icon-only.ant-input-search-button {
    border: unset;
    background: #f4f4f4;
    color: #393939;
  }
`
export const StyledLanguageText = styled(Text)`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #393939;
`

export const StyledUserText = styled(Text)`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #393939;
  margin-left: 8px;
`
export const StyledMenuBtn = styled(Button)`
  height: auto;
  padding: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`

export const StyledOptionsText = styled(Text)`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    color: #7b2e6f;
  }
`

export const StyledNotificationsWrapper = styled(Space)`
  a {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    height: 48px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
    color: #7b2e6f;
    background-color: transparent;
    //box-shadow: inset 0px 1px 0px #e2e2e2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .ant-space-item {
    display: flex;
  }

  > .ant-dropdown-menu-item + .ant-dropdown-menu-item {
    border-top: 1px solid #e2e2e2 !important;
  }
`

export const StyledNotificationsItem = styled(Space)`
  padding: 15px 0;
  margin: 0 12px;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 281px;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 0;
    text-align: left;
  }

  span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6b6b6b;
    text-align: left;
  }
`

export const StyledHeaderWrapper = styled(Space)`
  justify-content: space-between;
  display: flex;
  max-height: 100%;

  > .ant-space-item {
    height: 100%;
    line-height: normal;
  }

  & > .ant-space-item:first-child {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .principal-logo {
    display: flex;
  }
  @media (max-width: 800px) {
    .principal-logo {
      display: none;
    }
    > .ant-space-item + .ant-space-item {
      margin-right: auto;
      margin-left: 0;
    }
    > .ant-space-item + .ant-space-item + .ant-space-item {
      margin-right: 0;
      margin-left: auto;
    }
  }
`
export const StyledMenuIcon = styled(MenuOutlined)``
export const StyledSpace = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
`

export const StyledSideBar = styled.div`
  height: 100%;

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    color: #6b6b6b;
    min-width: 16px;
    margin-right: 12px;
    font-size: 20px;
    margin-bottom: 1px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  & .ant-menu-item-selected .anticon {
    color: #7b2e6f;
  }
  & .ant-menu-item-selected .ant-menu-item-icon {
    color: #7b2e6f;
  }

  & .ant-menu-submenu-open .ant-menu-submenu-title .anticon-tag {
    color: #7b2e6f;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
    padding-left: 16px !important;
    display: flex;
    align-items: center;
  }
  .close-button {
    color: black;
    margin-left: 200px;
    display: none;
  }
  @media (max-width: 800px) {
    .close-button {
      display: inline;
    }
  }
`
export const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item:hover {
    background-color: #f8f5f8;
  }
  .ant-dropdown-menu-item:hover .ant-typography {
    color: #7b2e6f !important;
  }

  .ant-btn > span {
    color: #eb5757 !important;
  }
`
