import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

.products-space .ant-pagination-options {
    display: none;
}

.products-space .ant-input-search-small .ant-input-search-button {
    border: 1px solid #e2e2e2;
}
  .ant-breadcrumb a{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6B6B;
  }

  .ant-form-vertical .ant-form-item-label > label{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  form .ant-input::placeholder{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-card-head-title{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-card-body{
    padding-top: 0;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  form .ant-form-item{
    margin-bottom: 16px;
  }

  .ant-divider-horizontal{
    margin: 16px 0;
  }

  .ant-card-head{
    min-height: unset;
  }

  .ant-card-head-title{
    padding-top: 16px;
  }

  .location-search-input {
    color: #393939 !important;
    width: 100% !important;
  }

  .autocomplete-dropdown-container .suggestion-item--active {
    color: #7b2e6f !important;
  }

  .attributes-table-page .ant-table-wrapper td.ant-table-cell .ant-typography a {
    display: none;
  }

  .new-attributes-page .ant-select-item-option-content, .edit-attributes-page .ant-select-item-option-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

  .styled-custom-card{
    padding-bottom: 30px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-footer {
    padding: 0 24px 24px;
  }

  .ant-modal-footer .ant-btn:first-child {
    border: none;
  }

  .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  span.ant-radio + * {
    font-family: Lato;
    font-weight: normal;
    font-style: normal;
    color: #6B6B6B;
  }

  .ant-picker-input > input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-checkbox-inner, .ant-radio-inner {
    border-color: #E2E2E2;
  }

  .ant-input-number-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-select-selection-placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-select-selection-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-select-item-option-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-card-body .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .ant-modal-confirm-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-modal-footer {
    padding: 0 24px 24px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-checkbox + span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6B6B6B;
  }

  @media (max-width: 767px) {
    .ant-radio-wrapper {
      white-space: unset;
    }

  }

  @media (max-width: 1200px) {
    .attributes-table-page .ant-table-title .ant-space .ant-space-item .ant-space > .ant-space-item {
      grid-column: 1 / 2 span;
    }

    .retailers-table-page .ant-table-title .ant-space .ant-space-item .ant-space > .ant-space-item {
      grid-column: 1 / 2 span;
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .attributes-table-page .ant-table-title .ant-space .ant-space-item .ant-space > .ant-space-item {
      grid-column: 1 / 3 span;
    }

    .retailers-table-page .ant-table-title .ant-space .ant-space-item .ant-space > .ant-space-item {
      grid-column: 1 / 3 span;
    }
  }

  .ant-input{
    color: #393939;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
  }

  .attributes-card .ant-select .ant-select-selector {
    //height: 40px;
  }

  .attributes-card .ant-select .ant-select-selector .ant-select-selection-item {
    //line-height: 38px;
    color: #393939;
  }

  .attributes-card .ant-select .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  .ant-select .ant-select-arrow {
    color: #393939;
  }

  .ant-select .ant-select-selection-item {
    color: #393939;
  }

  .attributes-card.new .ant-row.ant-row-no-wrap.ant-row-middle {
    margin-bottom: 16px;
  }

  .seo {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .seo.title {
    font-size: 18px;
    line-height: 24px;
    color: #1A0DAB;
    margin-bottom: 4px;
    display: block;
  }

  .seo.link {
    font-size: 14px;
    line-height: 24px;
    color: #006621;
  }

  .seo.description {
    font-size: 14px;
    line-height: 24px;
    color: #545454;
  }

  .modal-link-btn {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .url-input .ant-input-group-addon {
    font-family: Lato;
    border: unset;
  }

  .space-content-card {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0px 3px 5px -1px rgb(67 4 59 / 6%);
  }

  .ant-select-tree-title {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .search-space-mobile {
    display: none;
  }

  @media (max-width: 800px) {
    .sidebar-admin.active {
      z-index: 99;
      max-width: unset!important;
      background: rgba(0, 0, 0, 0.3);
    }

    .sidebar-admin {
      background: transparent;
      transform: translateX(0px);
      z-index: -1;
      position: fixed;
    }

    .sidebar-admin .ant-layout-sider-children {
      position: fixed;
      background: #ffffff;
      max-width: 300px;
      z-index: 99;
      transition: all 0.5s;
      left: -100%;
      width: 100%;
      overflow-y: scroll;
    }

    .sidebar-admin.active .ant-layout-sider-children {
      left: 0;
    }

    .search-space-mobile {
      display: flex;
      padding: 30px 24px 0 24px;
      width: 100%;
    }

    .sidebar-header {
      flex: auto;
      padding-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      align-items: flex-start;
    }

    .sidebar-header > .ant-space-item {
      flex: 1;
    }

    .sidebar-header > .ant-space-item a {
      display: block;
      width: 100%;
      text-align: center;
    }

    .sidebar-header > .ant-space-item .close-button {
      margin-left: auto;
      padding: 0;
      height: auto;
    }

    .sidebar-header > .ant-space-item a .logo-admin-page-sidebar {
      width: auto!important;
      height: auto!important;
      margin: 0!important;
    }

    .sidebar-header > .ant-space-item:last-child {
      text-align: right;
    }

    .search-space-mobile .ant-input-group-wrapper.ant-input-search {
      display: flex;
      width: 100%;
    }

    .search-space-mobile > .ant-space-item {
      width: 100%;
    }

    .sidebar-admin .ant-layout-sider-children .menu-sidebar {
      margin-top: 28px;
    }

    .sidebar-admin .ant-layout-sider-children .menu-sidebar-bottom {
      margin-bottom: 28px;
    }

    .search-space-mobile > .ant-space-item .ant-input-affix-wrapper {
      padding: 14px 12px;
    }

    .search-space-mobile > .ant-space-item .ant-input-affix-wrapper input {
      line-height: 20px;
    }

    .search-space-mobile > .ant-space-item .ant-input-search-button {
      height: 48px;
    }
  }

  .ant-table-tbody > .ant-table-row.waiting-new-seller > td.ant-table-cell {
    background: rgba(235, 87, 87, 0.05);
    color: #EB5757;
  }

  .ant-table-tbody > .ant-table-row.waiting-new-seller > td.ant-table-cell a {
    color: #EB5757;
  }

  .ant-table-tbody > .ant-table-row.waiting-new-seller:hover > td.ant-table-cell {
    background: rgba(235, 87, 87, 0.08);
  }

  @media print {
    .sidebar-admin {
      display: none;
    }

    .ant-layout-header {
      display: none;
    }

    .ant-layout-content {
      margin-top: 0!important;
    }

    .ant-page-header {
      display: none;
    }

    .order-timeline {
      display: none;
    }
  }

  .order-navigation {
    padding: 0;
  }

  @media (max-width: 767px) {
    .ant-card-body {
      padding: 0 12px 24px;
    }
    
    .ant-card-head {
      padding: 0 12px;
    }
  }

  .order-details-table .ant-card-body > .ant-space > .ant-space-item .ant-space-item {
    margin-right: 0px!important;
  }

  .dropdown-btn {
    border: unset;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    font-family: Lato;
    font-weight: 400;
    height: auto;
    color: #6B6B6B;
  }

  .ant-btn-dangerous.dropdown-btn {
    color: #ff4d4f;
  }

  @media (max-width: 767px) {
    .url-input .ant-input-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .url-input .ant-input-group .ant-input-group-addon {
      padding: 17px 11px;
      flex: 1;
      text-align: left;
    }

    .retailers-form-edit-bottom-btns {
      flex-direction: column;
    }

    .retailers-form-edit-bottom-btns .ant-btn {
      width: 100%;
    }

    .retailers-form-edit-bottom-btns .ant- .ant-row.ant-form-item {
      width: 100%;
    }

    .retailers-form-edit-bottom-btns > .ant-space-item {
      width: 100%;
      margin-right: 0!important;
    }

    .retailers-form-edit-bottom-btns > .ant-space-item .ant-btn.ant-btn-primary {
      width: 100%;
    }

    .retailers-form-edit-bottom-btns > .ant-space-item > .ant-space {
      width: 100%;
    }

    .retailers-form-edit-bottom-btns > .ant-space-item > .ant-space > .ant-space-item {
      width: 50%;
    }
  }

  .customer-edit-page .ant-modal-confirm-body .ant-modal-confirm-content,
  .customer-new-user-page .ant-modal-confirm-body .ant-modal-confirm-content{
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .customer-edit-page .ant-modal-confirm-body .ant-modal-confirm-title,
  .customer-new-user-page .ant-modal-confirm-body .ant-modal-confirm-title {
    color: #393939;
  }

  .customer-edit-page .ant-btn-default:hover {
    background-color: #7b2e6f;
    color: #fff;
    border-color: #7b2e6f;
  }

  /*@media (max-width: 767px) {
    .customer-edit-page .ant-page-header-heading-left,
    .customer-users-page .ant-page-header-heading-left,
    .customer-new-user-page .ant-page-header-heading-left,
    .notifications-page .ant-page-header-heading-left{
      flex-direction: row!important;
      align-items: center!important;
    }
  }*/

  /* orders table list mobile fix */ 
  @media (max-width: 991px) {
    /*.bo-orders-list .ant-table-content table thead.ant-table-thead {
      display: none;
    }


    .bo-orders-list .ant-table-content table td.ant-table-cell {
      display: flex;
      padding: 2px 0;
      border: unset;
      flex: 1 100%;
      max-width: 100%;
    }

    .bo-orders-list .ant-table-content table tr {
      display: flex;
      padding: 12px  20px 12px 0;
      position: relative;
      flex-wrap: wrap;
    }

    .bo-orders-list .ant-table-content table tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(1) {
      padding-top: 0;
    }

    .bo-orders-list .ant-table-content table tr.ant-table-placeholder td.ant-table-cell {
      justify-content: center;
    }

    .bo-orders-list .ant-table-content table tr + tr {
      border-top: 1px solid #ccc;
    }

    .bo-orders-list colgroup {
      display: none;
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(5), .ant-table-content table td.ant-table-cell:nth-child(11) {
      position: absolute;
      right: 20px;
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(5) {
      top: 40px;
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(6), .ant-table-content table td.ant-table-cell:nth-child(7) {
      flex: 0 auto;
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(2), .ant-table-content table td.ant-table-cell:nth-child(8), .ant-table-content table td.ant-table-cell:nth-child(9) {
      display: none;
    }

    .bo-orders-list .ant-table-title + .ant-table-container {
      margin: 0 -20px 0 0;
    }

    .bo-orders-list table {
      width: 100%!important;
      min-width: unset!important;
    }

    .bo-orders-list .ant-layout-content > div {
      margin: 0;
    }*/

    /* css to not insert
    .ant-table-content table td.ant-table-cell:before {
      content: '#';
      display: flex;
      flex: 0 50%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #43043B;
    }

    .ant-table-content table td.ant-table-cell:nth-child(2):before {
      content: 'Tipo';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(3):before {
      content: 'Recolha';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(4):before {
      content: 'Cliente';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(5):before {
      content: 'Total';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(6):before {
      content: 'Pagamento';
    }

    .ant-table-content table td.ant-table-cell:nth-child(7):before {
      content: 'Processamento';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(8):before {
      content: 'Itens';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(9):before {
      content: 'Entrega';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(10):before {
      content: 'Retalhista';
    }

    .bo-orders-list .ant-table-content table td.ant-table-cell:nth-child(11):before {
      content: 'Data';
    }*/
  }

  /* end orders table list mobile fix */ 

`
